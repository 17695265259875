.container-themes{
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 3px solid var(--background-theme-first);
    margin-top: 20px;
    padding: 5px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.container-themes button{
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 3px;
    size: auto;
    margin: 0;
}
.container-themes button:hover{
    transform: scale(1.2);
}
.theme-y svg{
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 50px;
    line-height: 50px;
    fill: var(--background-theme-first-yellow);
}
.theme-r svg{
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 50px;
    line-height: 50px;
    fill: var(--background-theme-first-red);
}
.theme-b svg{
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 50px;
    line-height: 50px;
    fill: var(--background-theme-first-blue);
}