img{
    width: 100px;
    height: 100%;
    top: 0;
    left: 0;
}
h1{
    font-size: 45px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.nameHeader h1{
    text-align: center;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
} 