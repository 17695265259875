:root {
	position: relative;
	width: auto;
	height: auto;
	/*principales*/
	--background-theme-first: #f9e37f;
	--background-theme-first-yellow: #f9e37f;
	--background-theme-first-red: #fda6c8;
	--background-theme-first-blue: #74c8d9;
	/*secundarios*/
	--background-theme-second: #fefcc5;
	--background-theme-second-yellow: #fefcc5;
	--background-theme-second-red: #fddeeb;
	--background-theme-second-blue: #def8fc;
}

body {
	padding: 0;
	height: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
	background-color: var(--background-theme-first);
	color: black;
	font-family: "Comic Sans MS", "Comic Sans", cursive;
}

[data-theme='yellow']{
	--background-theme-first: var(--background-theme-first-yellow);
	--background-theme-second: var(--background-theme-second-yellow);
}

[data-theme='red']{
	--background-theme-first: var(--background-theme-first-red);
	--background-theme-second: var(--background-theme-second-red);
}

[data-theme='blue']{
	--background-theme-first: var(--background-theme-first-blue);
	--background-theme-second: var(--background-theme-second-blue);
}