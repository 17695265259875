.card-loading {
    margin: 0 auto;
    width: 90%;
    padding-bottom: 25px;
    box-sizing: border-box;
    border: 5px solid #000;
    border-radius: 10px;
    background-color: var(--background-theme-second);
}
.loading-themes{
    display: flex; 
    justify-content: center;
    padding: 15px;
}
.container-loading{
    margin: 0 auto;
    width: 100%;
    background-color: transparent;
}
.container-loading img{
    padding-bottom: 35px;
    position: relative;
    width: 60%;
    top: auto;
}
.container-loading h2{
    margin: auto;
    text-align: center;
    padding: 0px;
}
