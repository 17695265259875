.card-video {
    margin: 0 auto;
    padding: 2px;
    width: 90%;
    box-sizing: border-box;
    border: 5px solid #000;
    border-radius: 10px;
    background-color: var(--background-theme-second);
}
.video-themes{
    display: flex;
    padding-top: 2%;
    padding-right: 25px;
    padding-bottom: 4%;
    justify-content: center;
}
.container-video{
    margin: 0px;
    padding: 5px;
    width: 100%;
    background-color: transparent;
}
.container-video .info{
    text-align: left;
}
.container-video .download{
    border: 3px solid #000;
    padding: 1px 3px;
    border-radius: 10px;
    margin-bottom: 0px !important;
    background-color: var(--background-theme-first);
}
.container-video .download:hover{
    transform: scale(1.1);
}
.container-video .download h4{
    margin-bottom: 0px !important;
    padding:  0px 25px;
}
.container-video img{
    padding-bottom: 35px;
    position: relative;
    width: 60%;
    top: auto;
}
.container-share{
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    border: 3px solid var(--background-theme-first);
    margin: 0px 5px 5px 5px;
    padding: 0px 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.container-share button{
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin: 0 auto;
}
.container-share button:hover{
    transform: scale(1.15);
}
.facebook svg{
    width: 100%;
    height: 100%;
    fill: #0087CB;
}
.twitter svg{
    width: 100%;
    height: 100%;
    fill: #0DDCFB;
}
.linkedin svg{
    width: 100%;
    height: 100%;
    fill: #0074B2;
}
.pinterest svg{
    width: 100%;
    height: 100%;
    fill: #BD081C;
}
.telegram svg{
    width: 100%;
    height: 100%;
    fill: #0087CB;
}
.whatsapp svg{
    width: 100%;
    height: 100%;
    fill: #009D00;
}
/*banner*/
.banner-video {
    margin: 0 auto;
    padding: auto;
    width:80%;
    background-color: transparent;
}

.banner-video section {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    align-items: center;
    width: 100%;
    background-color: transparent;
}

.banner-video section img {
    flex-grow: 1;
    object-fit: cover;
    opacity: 0.7;
    transition: 0.5s ease;
    position: relative;
    padding: 0;
    width: 25%;
    top: auto;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
    border: 3px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
    border-radius: 15px;
}

.banner-video section img:hover {
    width: 70%; /*tamaño de las imagenes del banner*/
    padding: 0;
    opacity: 1;
    filter: contrast(125%);
    transition: 3s all;
    border: #252525 5px solid;
    border-radius: 30px;
}

.container-code {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 3px solid var(--background-theme-first);
    margin: 3px;
    padding: 0px 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.container-code button{
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 3px;
    size: auto;
    margin: 0;
}
.container-code button:hover{
    transform: scale(1.15);
}
.github svg{
    width: 100%;
    height: 100%;
    font-weight: 500;
    font-size: 50px;
    line-height: 50px;
    fill: #303030;
}
.container-video .mp4{
    padding: auto;
    margin: 3% auto;
    text-align: center;
}
.player{
    border-radius: 15px;
    border: 4px solid var(--background-theme-first);
    width: 100%;
    margin: 0 auto;
    position: relative;
}
.playControler{
    border: none;
    border-radius: 100%;
    background-color: transparent;
    width: 150px;
}
.playControler svg{
    width: 100%;
    height: 100%;
    font-weight: 800;
    font-size: 150px;
    line-height: 150px;
    fill: var(--background-theme-first);
}
.player .playControler{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}
.player .playControler i{
    padding-top: 15px;
}
.playControler:hover svg{
    transform: scale(1.1);
}
