.card {
    margin: 0 auto;
    padding: 10px;
    width: 90%;
    box-sizing: border-box;
    border: 5px solid #000;
    border-radius: 10px;
    background-color: var(--background-theme-second);
}
h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 46px;
    text-align: left;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color:transparent;
}

h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 25px;
    text-align: left;
    background-color: transparent;
}

.container {
    margin: 0 auto;
    padding: 0;
    width: 75%;
    background-color: transparent;
}

.container .form {
    margin: 0 auto;
    padding: 0;
    background-color: transparent;
}

.container .form input {
    margin: 10px 2%;
    padding: 8px;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    border: 3px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.container .form button {
    margin: 0px 2%;
    padding: 4px;
    border: 3px solid #000000;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-color: var(--background-theme-first);
}

.container .form button:hover {
    transition: 0.5s ease;
    transform: scale(1.1);
}

.container .form button i {
    padding: 0;
    margin: 0;
    font-size: 30px;
}

.container .form button svg {
    padding: 0;
    margin: 0;
    font-size: 40px;
}

/*banner*/
.banner {
    margin: 0 auto;
    padding: auto;
    width: 80%;
    background-color: transparent;
}

.banner section {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: transparent;
}

.banner section img {
    flex-grow: 1;
    object-fit: cover;
    opacity: 0.7;
    transition: 0.5s ease;
    position: relative;
    width: 25%;
    top: auto;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4));
    border: 3px solid #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
    border-radius: 46px;
}

.banner section img:hover {
    width: 70%; /*tamaño de las imagenes del banner*/
    padding: 0;
    opacity: 1;
    filter: contrast(125%);
    transition: 3s all;
    border: #252525 5px solid;
    border-radius: 46px;
}
.form-themes{
    margin-left: auto;
    padding-top: 2px;
    padding-right: 15px;
    background-color: transparent;
}